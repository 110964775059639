<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps<{
    error: NuxtError
}>()

const message = computed(() => {
    if (props.error.statusCode === 404) {
        return 'La página que buscas no existe.'
    }

    return 'Ha ocurrido un error.'
})

useHead({
    title: `Error ${props.error.statusCode}`
})
</script>

<template>
    <div class="h-[100dvh] flex flex-col items-center justify-center overflow-hidden gap-4">
        <h1 class="text-4xl">Error {{ error.statusCode }}</h1>
        <p>{{ message }}</p>
        <UButton @click="clearError({ redirect: '/' })" variant="link" trailing-icon="material-symbols:arrow-forward-rounded">Ir al inicio</UButton>
    </div>
</template>