<script setup lang="ts">
useHead({
	script: [
		{
			src: 'https://analytics.ahrefs.com/analytics.js',
			async: true,
			'data-key': 'nREotsDN0hOY7ABOieXNMQ',
		}
	],
	link: [
		{
			rel: 'apple-touch-icon',
			sizes: '180x180',
			href: 'https://ehsv.org/apple-touch-icon.png',
		},
		{
			rel: 'apple-touch-icon',
			sizes: '120x120',
			href: 'https://ehsv.org/apple-touch-icon-120x120.png',
		},
		{
			rel: 'apple-touch-icon',
			sizes: '152x152',
			href: 'https://ehsv.org/apple-touch-icon-152x152.png',
		},
	]
})

const route = useRoute()

watch(() => route.path, () => {
	const scrollingElement = document.getElementById('scrolling-element')

	if (scrollingElement) {
		scrollingElement.scrollTo({ top: 0, behavior: 'smooth' })
	}
})
</script>

<template>
	<div class="h-[100dvh] overflow-y-auto print:overflow-auto print:h-auto" id="scrolling-element">
		<NuxtRouteAnnouncer />
		<div class="min-h-[100dvh]">
			<NuxtLayout>
				<NuxtPage />
			</NuxtLayout>
		</div>
	</div>
</template>
